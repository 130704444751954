<template>
  <div class="relative mx-auto operations-step">
    <span class="block text-2xl font-semibold text-darkblue text-center">
      <i class="fa-solid fa-forklift"></i>
      5th Step: Equipment
    </span>
    <span class="block mt-2 text-base font-normal text-darkblue text-center">
      (Select as much equipment as you can provide or are willing to)
    </span>

    <!-- Switches for equipment options -->
    <SwitchesCheckboxes
      :options="equipmentOptions"
      :model-value="localEquipmentData"
      @update:modelValue="updateEquipmentData"
    />
  </div>
</template>

<script>
import SwitchesCheckboxes from "@/components/common/Forms/SwitchesCheckboxes.vue";

export default {
  name: "EquipmentStep",
  components: { SwitchesCheckboxes },
  props: {
    equipmentData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localEquipmentData: { ...this.equipmentData }, // Local copy of equipmentData
      equipmentOptions: [
        {
          name: "temperatureHumidityControl",
          label: "Temperature & Humidity Control",
          value: false,
        },
        {
          name: "refrigeratedChamber",
          label: "Refrigerated Chamber",
          value: false,
        },
        {
          name: "fireProtectionSystem",
          label: "Fire Protection System",
          value: false,
        },
      ],
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    equipmentData: {
      deep: true,
      handler(newVal) {
        this.localEquipmentData = { ...newVal };
      },
    },
  },
  methods: {
    updateEquipmentData(updatedData) {
      this.localEquipmentData = updatedData;
      // Emit the update to parent component
      this.$emit("update:equipmentData", updatedData);
    },
  },
};
</script>
